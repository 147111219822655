import React from "react"
import Layout from "../components/layout"
// import SEO from "../components/seo";
// import featureImage from "../../static/images/hero2.png";
import featureImage from "../../static/images/mapp-home.jpg"
import houseImage from "../../static/images/house.jpg"
import thumbnailEvent from "../../static/images/team.png"
import thumbnailEssentials from "../../static/images/essentials.png"
import thumbnailBoard from "../../static/images/foundation.png"
import thumbnailSlack from "../../static/images/slack-mapp.png"
import thumbnailNews from "../../static/images/pulse1.png"
import thumbnailTeams from "../../static/images/one-on-one.png"
import thumbnailStaff from "../../static/images/performance1.png"
import learningPath from "../../static/images/learning-path.jpg"
import mars from "../../static/images/mars.png"
import onboarding from "../../static/images/onboarding.jpg"
import Subscribe from "../components/subscribe/subscribe"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import NewsletterFeedback from "../components/NewsletterFeedback/NewsletterFeedback"
import ReactPlayer from "react-player"
import PlayCircleFilledWhiteOutlined from "@material-ui/icons/PlayCircleFilledWhiteOutlined"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          Level Up Your Management Skills | Service for Engineering Managers
        </title>
        <meta
          name="description"
          content="Managers.App provides the tools needed to apply management best practices in your work for better outcomes. Get started for FREE."
        />
      </Helmet>
      <div className={"page-header home"}>
        <div>
          <h1 style={{ color: "#8953b7", fontWeight: "normal" }}>
            Revolutionize your Management with AI-Driven Insights
          </h1>
          <h3
            style={{
              paddingLeft: "20%",
              paddingRight: "20%",
              fontSize: "22px",
            }}
          >
            {/* ManagersApp Helps Leaders Grow their Team Members and Create Better Outcomes */}
            Helping Leaders Unlock The Full Potential Of Their Team
          </h3>
          <div
            style={{
              position: "relative",
              paddingTop: "56.25%",
              marginLeft: "10%",
              marginRight: "10%",
            }}
          >
            <ReactPlayer
              url="https://youtu.be/qJUzTSiSH0E"
              width="100%"
              height="100%"
              playing
              controls
              playIcon={
                <PlayCircleFilledWhiteOutlined style={{ fontSize: "100px" }} />
              }
              light={featureImage}
              style={{ position: "absolute", top: "0", left: "0" }}
            />
          </div>
          <div>
            <h3 style={{ fontWeight: "normal", fontSize: "24px" }}>
              Try ManagersApp Today
            </h3>
            <DemoButton
              onClick={() => {
                window.open("https://calendly.com/ersiny/30", "_blank")
              }}
            >
              Book A Demo
            </DemoButton>
            <TryButton
              onClick={() => {
                window.location = "https://app.managers.app/signin"
              }}
            >
              Sign-Up For Free
            </TryButton>
          </div>
        </div>
      </div>

      <div className={"container"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
          }}
        >
          <h1 style={{ color: "#8953b7", fontWeight: "normal" }}>
            Unlock Your Team's Potential with AI-Powered Tools
          </h1>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          <div className={"features"}>
            <div className={"feature__item"}>
              <div className={"row"}>
                <div className={"col-7 first"}>
                  <div className={"thumbnail"}>
                    <div className={"thumbnail"}>
                      <img alt={"Foundation Module"} src={thumbnailSlack} />
                    </div>
                  </div>
                </div>
                <div className={"col-5"}>
                  <div className={"feature__content"}>
                    <h2>ManagersBot - Slack App</h2>
                    <p>
                      Transform Slack conversations into actionable 1:1 topics
                      and performance summaries for AI powered decision-making.
                      <br />
                      <br />
                      <LearnButton
                        onClick={() => {
                          window.location = "/install-managersapps-slack-bot/"
                        }}
                      >
                        Learn More
                      </LearnButton>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={"feature__item"}>
              <div className={"row"}>
                <div className={"col-7"}>
                  <div className={"feature__content"}>
                    <p>
                      Introducing{" "}
                      <span style={{ fontWeight: "bold" }}>MARS</span>
                      <em>(Managerial Assistant and Resource Servant)</em> on
                      Managers.App! We've integrated Artificial Intelligence to
                      help managers streamline their work and make better
                      decisions. With our AI-powered question answering system,
                      you can get quick and accurate answers to your questions.
                      Our proofreading tool ensures that your content is
                      error-free and polished. And with our 9Box assessment, you
                      can easily evaluate your team's performance and identify
                      areas for improvement. These new features are designed to
                      help you prioritize execution and build strong
                      relationships with your team. <br />
                      <br />
                      Sign up with Managers.App today and experience the power
                      of AI in people management!
                      <br />
                    </p>
                  </div>
                </div>
                <div className={"col-5 first"}>
                  <div className={"thumbnail"}>
                    <img alt={"Foundation Module"} src={mars} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"features"}>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-7 first"}>
                <div className={"thumbnail"}>
                  <ReactPlayer
                    url="https://youtu.be/3Il_B52y5OI"
                    playing
                    controls
                    playIcon={
                      <PlayCircleFilledWhiteOutlined
                        style={{ fontSize: "100px", color: "white" }}
                      />
                    }
                    light={houseImage}
                  />
                </div>
              </div>
              <div className={"col-5"}>
                <div className={"feature__content"}>
                  <h2>
                    Let's Build a Perfect <strike>House</strike> Team Together
                  </h2>
                  <p>
                    We belive 3D experiences help communicate signals to leaders
                    in a way that is simple and engaging.
                    <br />
                    <br />
                    <LearnButton
                      onClick={() => {
                        window.location =
                          "/lets-build-a-perfect-house-together/"
                      }}
                    >
                      Learn More
                    </LearnButton>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-7"}>
                <div className={"feature__content"}>
                  <h2>Grow Talent Through Career Frameworks</h2>
                  <p>
                    Foundation Module helps managers set expectations with their
                    teams and hold them accountable for their work.
                    <br />
                    <br />
                    Career Framework is the source for how to achieve impact and
                    grow in a role.
                    <br />
                    <br />
                    Career Framework allows managers to operate with consistency
                    when hiring new talent and growing existing team members.
                  </p>
                </div>
              </div>
              <div className={"col-5 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Foundation Module"} src={thumbnailBoard} />
                </div>
              </div>
            </div>
          </div>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-5 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Team Module"} src={thumbnailEvent} />
                </div>
              </div>
              <div className={"col-7"}>
                <div className={"feature__content"}>
                  <h2>Stay On Top Of Areas That Need Improvement</h2>
                  <p>
                    Team Module allows managers to set up their team and track
                    critical information about each member. ManagersApp provides
                    reminders and suggestions on coaching and training your
                    team.
                  </p>
                  {/* <div > 
                                        <div style={{float: "right", paddingRight: "5%"}}>
                                            <LearnButton onClick={() => {window.location = "/team-management-module"}}>Learn More</LearnButton>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-7"}>
                <div className={"feature__content"}>
                  <h2>Run Effective 1:1s</h2>
                  <p>
                    The one-on-one module helps managers organize and execute
                    effective 1:1s. It automatically creates a 1:1 agenda with
                    important topics and action items.
                    <br />
                    <br />
                    You can add an interactive board and collaborate with your
                    team member to create an agenda for your weekly.
                  </p>

                  {/* <div >
                                        <div style={{ float: "left" }}>
                                            <LearnButton onClick={() => { window.location = "/one-on-one-meetings-module" }}>Learn More</LearnButton>
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div className={"col-5 first"}>
                <div className={"thumbnail"}>
                  <img alt={"One-On-One Module"} src={thumbnailTeams} />
                </div>
              </div>
            </div>
          </div>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-5 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Pulse Module"} src={thumbnailNews} />
                </div>
              </div>
              <div className={"col-7"}>
                <div className={"feature__content"}>
                  <h2>Know How Your Team is Doing</h2>
                  <p>
                    Pulse Module lets managers get real-time insight about how
                    their team members are doing through 1-minute weekly pulse
                    surveys. Pulse module automatically creates 1:1 agenda items
                    with conversation starters when an area needs attention.
                  </p>
                </div>
                <div>
                  {/* <div style={{ float: "right", paddingRight: "5%" }}>
                                        <LearnButton onClick={() => { window.location = "/team-management-module" }}>Learn More</LearnButton>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-7"}>
                <div className={"feature__content"}>
                  <h2>Level Up Your Management Skills</h2>
                  <p>
                    People who are managed by great managers feel more
                    satisfaction at work. They also stay with their company
                    longer, making more of a positive impact. However, if you
                    want great managers at your company, you must invest in
                    developing them.
                    <br />
                    <br />
                    ManagersApp’s Learning Path’s are aimed at helping managers
                    learn how to work effectively with their teams, peers and
                    higher-ups.
                  </p>
                  <div>
                    {/* <div style={{ float: "left" }}>
                                            <LearnButton onClick={() => { window.location = "/learning-paths" }}>Learn More</LearnButton>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className={"col-5 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Essentials Module"} src={learningPath} />
                </div>
              </div>
            </div>
          </div>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-5 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Performance Module"} src={thumbnailStaff} />
                </div>
              </div>
              <div className={"col-7"}>
                <div className={"feature__content"}>
                  {/* <h2>Effective <span style={{ textDecoration: "line-through" }}>Performance</span> Growth Management</h2> */}
                  <h2>Grow Team Members</h2>
                  <p>
                    Instead of running an annual, bi-annual, or quarterly review
                    process which is heavy on everyone involved and ineffective,
                    the ManagersApp performance module allows managers to
                    continuously evaluate performance and provide actionable
                    feedback to team members.
                    <br />
                    <br />
                    Our method keeps managers informed, avoiding recency bias by
                    collecting timely 360 feedback from reviewers - feedback
                    which can be used to coach the team at the time when this
                    information is most effective.
                  </p>
                  <div>
                    {/* <div style={{ float: "right", paddingRight: "5%" }}>
                                            <LearnButton onClick={() => { window.location = "/performance-management-module" }}>Learn More</LearnButton>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-7"}>
                <div className={"feature__content"}>
                  <h2>Access to Curated Resources</h2>
                  <p>
                    Essentials Module curates peer-reviewed articles, videos,
                    tools, and question&answers related to managing technical
                    talent.
                    <br />
                    <br />
                    Essentials module content is integrated throughout the app
                    so it can be accessed when it is needed.
                  </p>
                  <div>
                    {/* <div style={{ float: "left" }}>
                                            <LearnButton onClick={() => { window.location = "/essentials-module" }}>Learn More</LearnButton>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div className={"col-5 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Essentials Module"} src={thumbnailEssentials} />
                </div>
              </div>
            </div>
          </div>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-5 first"}>
                <div className={"thumbnail"}>
                  <img alt={"Performance Module"} src={onboarding} />
                </div>
              </div>
              <div className={"col-7"}>
                <div className={"feature__content"}>
                  {/* <h2>Effective <span style={{ textDecoration: "line-through" }}>Performance</span> Growth Management</h2> */}
                  <h2>Successfully Onboard New Hires</h2>
                  <p>
                    As mangers we are accountable for the successful onboarding
                    of new hires. We need to prepare our team for the new
                    teammate, and improve the onboarding process continuously.
                    <br />
                    <br />
                    You can create an interactive board using an onboarding
                    template and collaborate with your new hire to ensure that
                    they are progressing according to the milestones you set for
                    them.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"page-section home"}>
        <h1 style={{ color: "#3d84a8" }}>
          Subscribe to Managers Weekly Newsletter
        </h1>
        <p style={{ paddingLeft: "20%", paddingRight: "20%" }}>
          Here is What Our Readers Say
        </p>
      </div>
      <div className={"container"}>
        <NewsletterFeedback />
      </div>
      <div className={"container"}>
        <div className={"features"}>
          <div className={"feature__item"}>
            <div className={"row"}>
              <div className={"col-12 first"}>
                <Subscribe title="" buttonText="Subscribe" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"call-to-action"}>
        <div className={"container"}>
          <div className={"call-to-action__content"}>
            <h2>Level Up Your Management Skills</h2>
            <p>
              Gain access to tools that will help you with your daily work and
              level up your management skills
            </p>
          </div>
          <div className={"button"}>
            <a href="https://app.managers.app/signin">Get Started for FREE</a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const LearnButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 50px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: #93abd3;
  border-radius: 4px;
  padding: 0px 20px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const DemoButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.primary};
  border-radius: 20px;
  padding: 0px 20px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const TryButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 20px;
  padding: 0px 20px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export default IndexPage
